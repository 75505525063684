export const indiceList = ["NIFTY", "BANKNIFTY"];
export const indiceListTotal = ["NIFTY", "BANKNIFTY", "FINNIFTY", "MIDCPNIFTY"];
export const indicesUrlMap = {
    NIFTY: "https://services.niftytrader.in/webapi/symbol/today-spot-data?symbol=NIFTY+50",
    BANKNIFTY:
        "https://services.niftytrader.in/webapi/symbol/today-spot-data?symbol=NIFTY+BANK",
    FINNIFTY:
        "https://services.niftytrader.in/webapi/symbol/today-spot-data?symbol=NIFTY+FIN+SERVICE",
    MIDCPNIFTY:
        "https://services.niftytrader.in/webapi/symbol/today-spot-data?symbol=MIDCPNIFTY",
};

export const BASEAPIURL =
    process.env.REACT_APP_ENVIRONMENT === "production"
        ? "https://store.suyabrokerage.in/api/v1/"
        : "http://127.0.0.1:8000/api/v1/";

export const BASESOCKETURL =
    process.env.REACT_APP_ENVIRONMENT === "production"
        ? "wss://store.suyabrokerage.in/ws/test/"
        : "ws://localhost:8000/ws/test/";

export const INDEXWISEEXPIRYLISTURL =
    BASEAPIURL + "getIndexWiseExpiryList?indexName=";
export const INDEXNAMEWITHEXPIRYURL =
    BASEAPIURL + "getTokenDataMap?indexNameWithExpiry=";
export const FIIDIIURL = BASEAPIURL + "fii-dii/?limit=24";
export const FNOURL = BASEAPIURL + "future-options/?limit=20";
export const INDEXPERDAYURL = BASEAPIURL + "indexes/?limit=20";
export const ADDUSERURL = BASEAPIURL + "addUser/";

export const DefaultIndicesData = [
    {
        token: "1",
        // CEAnalysis: "Bear",
        CEOI: "12",
        CEVolume: "40",
        CELTP: "121",
        // PEAnalysis: "Bear",
        PEOI: "12",
        PEVolume: "40",
        PELTP: "121",
    },
    {
        token: "2",
        // CEAnalysis: "Bull",
        CEOI: "13",
        CEVolume: "41",
        CELTP: "122",
        // PEAnalysis: "Bear",
        PEOI: "12",
        PEVolume: "40",
        PELTP: "121",
    },
    {
        token: "3",
        // CEAnalysis: "Bear",
        CEOI: "14",
        CEVolume: "42",
        CELTP: "123",
        // PEAnalysis: "Bear",
        PEOI: "12",
        PEVolume: "40",
        PELTP: "121",
    },
    {
        token: "4",
        // CEAnalysis: "Bull",
        CEOI: "15",
        CEVolume: "43",
        CELTP: "124",
        // PEAnalysis: "Bear",
        PEOI: "12",
        PEVolume: "40",
        PELTP: "121",
    },
    {
        token: "5",
        // CEAnalysis: "Bear",
        CEOI: "16",
        CEVolume: "44",
        CELTP: "125",
        // PEAnalysis: "Bear",
        PEOI: "12",
        PEVolume: "40",
        PELTP: "121",
    },
    {
        token: "6",
        // CEAnalysis: "Bull",
        CEOI: "17",
        CEVolume: "46",
        CELTP: "126",
        // PEAnalysis: "Bear",
        PEOI: "12",
        PEVolume: "40",
        PELTP: "121",
    },
    {
        token: "7",
        // CEAnalysis: "Bear",
        CEOI: "18",
        CEVolume: "47",
        CELTP: "127",
        // PEAnalysis: "Bear",
        PEOI: "12",
        PEVolume: "40",
        PELTP: "121",
    },
    {
        token: "8",
        // CEAnalysis: "Bull",
        CEOI: "19",
        CEVolume: "48",
        CELTP: "128",
        // PEAnalysis: "Bear",
        PEOI: "12",
        PEVolume: "40",
        PELTP: "121",
    },
    {
        token: "9",
        // CEAnalysis: "Bear",
        CEOI: "20",
        CEVolume: "49",
        CELTP: "129",
        // PEAnalysis: "Bear",
        PEOI: "12",
        PEVolume: "40",
        PELTP: "121",
    },
    {
        token: "10",
        // CEAnalysis: "Bull",
        CEOI: "21",
        CEVolume: "50",
        CELTP: "130",
        // PEAnalysis: "Bear",
        PEOI: "12",
        PEVolume: "40",
        PELTP: "121",
    },
    {
        token: "11",
        // CEAnalysis: "Bear",
        CEOI: "12",
        CEVolume: "40",
        CELTP: "121",
        // PEAnalysis: "Bear",
        PEOI: "12",
        PEVolume: "40",
        PELTP: "121",
    },
    {
        token: "12",
        // CEAnalysis: "Bull",
        CEOI: "13",
        CEVolume: "41",
        CELTP: "122",
        // PEAnalysis: "Bear",
        PEOI: "12",
        PEVolume: "40",
        PELTP: "121",
    },
    {
        token: "13",
        // CEAnalysis: "Bear",
        CEOI: "14",
        CEVolume: "42",
        CELTP: "123",
        // PEAnalysis: "Bear",
        PEOI: "12",
        PEVolume: "40",
        PELTP: "121",
    },
    {
        token: "14",
        // CEAnalysis: "Bull",
        CEOI: "15",
        CEVolume: "43",
        CELTP: "124",
        // PEAnalysis: "Bear",
        PEOI: "12",
        PEVolume: "40",
        PELTP: "121",
    },
];

export const DummyDataForIndices = {
    token: "20",
    // CEAnalysis: "Bull",
    CEOI: "21",
    CEVolume: "50",
    CELTP: "130",
    // PEAnalysis: "Bear",
    PEOI: "12",
    PEVolume: "40",
    PELTP: "121",
};

export const DummyDataForFiiDii = [
    {
        DII_BUY: "14,438.64",
        DII_NET: "1,429.11",
        DII_SELL: "13,009.53",
        Date: "30-Apr-2024",
        FII_BUY: "20,925.96",
        FII_NET: "1,071.93",
        FII_SELL: "19,854.03",
    },
];

export const dummyIndiceSummary = {
    last_trade_price: "22,125.25",
    change_value: "81.85",
    change_per: "0.37%",
};
