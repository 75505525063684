import React, { useEffect } from "react";
import { FIIDIIURL, FNOURL } from "../data/Constants";
import Styles from "../styles/FiiDiiStyles.module.css";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { updateFiiDii, updateFiiFnO } from "../redux/fiiSlice";
import CashMarketDailySummary from "./CashMarketDailySummary";

const FiiDii = () => {
    const reduxFiiDii = useSelector((state) => state.fii.fiiDii);
    const dispatch = useDispatch();

    useEffect(() => {
        const fetchFiiDii = async () => {
            try {
                const response = await axios.request({
                    url: FIIDIIURL,
                    method: "GET",
                });
                let data = response.data.message;
                console.log(response.data.message);
                dispatch(updateFiiDii(data));
            } catch (error) {
                // console.error(error);
            }
        };
        fetchFiiDii();

        const fetchFIIFnO = async () => {
            try {
                const response = await axios.request({
                    url: FNOURL,
                    method: "GET",
                });
                let data = response.data.message;
                dispatch(updateFiiFnO(data));
            } catch (error) {
                // console.error(error);
            }
        };
        fetchFIIFnO();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const createFiiDiiBodyRows = () => {
        return (
            <tbody className={Styles.fiiDiiCashTableBody}>
                <tr>
                    <td>BUY</td>
                    <td>{reduxFiiDii[0]?.DII_BUY}</td>
                    <td>{reduxFiiDii[0]?.FII_BUY}</td>
                </tr>
                <tr>
                    <td>SELL</td>
                    <td>{reduxFiiDii[0]?.DII_SELL}</td>
                    <td>{reduxFiiDii[0]?.FII_SELL}</td>
                </tr>
                <tr>
                    <td>NET VALUE</td>
                    <td>
                        <div
                            className={
                                parseFloat(reduxFiiDii[0]?.DII_NET) > 0
                                    ? Styles.trendGreen
                                    : Styles.trendRed
                            }
                        >
                            {reduxFiiDii[0]?.DII_NET}
                        </div>
                    </td>
                    <td>
                        <div
                            className={
                                parseFloat(reduxFiiDii[0]?.FII_NET) > 0
                                    ? Styles.trendGreen
                                    : Styles.trendRed
                            }
                        >
                            {reduxFiiDii[0]?.FII_NET}
                        </div>
                    </td>
                </tr>
            </tbody>
        );
    };

    const createFiiDiiNetChange = () => {
        let fiiNet = parseFloat(reduxFiiDii[0]?.FII_NET);
        let diiNet = parseFloat(reduxFiiDii[0]?.DII_NET);
        let fiiDiiNetChange = fiiNet + diiNet;
        return (
            <div
                className={
                    fiiDiiNetChange > 0
                        ? Styles.netChangePositive
                        : Styles.netChangeNegative
                }
            >
                <div>{fiiDiiNetChange.toFixed(2)}</div>
                <div>
                    OVERALL {fiiDiiNetChange > 0 ? "BUYING " : "SELLING "}
                    PRESSURE
                </div>
            </div>
        );
    };

    return (
        <div className={Styles.fiiDii}>
            <div className={Styles.fiiDiiCash}>
                <div className={Styles.fiiDiiCashTableContainer}>
                    <table className={Styles.fiiDiiCashTable}>
                        <thead>
                            <tr className={Styles.fiiDiiCashTableHeader}>
                                <th>Category</th>
                                <th>DII</th>
                                <th>FII/FPI*</th>
                            </tr>
                        </thead>
                        {createFiiDiiBodyRows()}
                    </table>
                    {createFiiDiiNetChange()}
                </div>
            </div>
            <CashMarketDailySummary />
        </div>
    );
};

export default FiiDii;
