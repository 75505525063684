import React from "react";
import Styles from "../styles/FIIFnOStyles.module.css";
import { useSelector } from "react-redux";
import FiiCallPutChangeChart from "./charts/FiiCallPutChangeChart";
import FiiFutureChart from "./charts/FiiFutureChart";
import FiiCallPutOiChart from "./charts/FiiCallPutOiChart";
import FiiFutureOiChart from "./charts/FiiFutureOiChart";

const participantList = ["Client", "DII", "FII", "Pro"];

const FIIFnO = () => {
    const reduxFiiFno = useSelector((state) => state.fii.fiiFnO);

    const trendCheckerNetActivity = (key, data) => {
        return data === 0 ? (
            <td>NIL</td>
        ) : data > 0 ? (
            <td className={Styles.trendGreen}>Bought {key}</td>
        ) : (
            <td className={Styles.trendRed}>Sold {key}</td>
        );
    };

    const trendCheckerView = (key, data) => {
        return data === 0 ? (
            <td>NIL</td>
        ) : data > 0 ? (
            key === "Put" ? (
                <td className={Styles.trendRed1}>Bearish</td>
            ) : (
                <td className={Styles.trendGreen1}>Bullish</td>
            )
        ) : key === "Put" ? (
            <td className={Styles.trendGreen1}>Bullish</td>
        ) : (
            <td className={Styles.trendRed1}>Bearish</td>
        );
    };

    const participantTable = (key, data) => {
        return (
            <>
                <tr>
                    {key === "Client" ? (
                        <td
                            rowSpan={3}
                            style={{
                                borderColor: "#4006ea",
                                borderRight: "1px solid white",
                                fontSize: "calc(2vh + 2vw)",
                            }}
                        >
                            {key}
                        </td>
                    ) : key === "DII" ? (
                        <td
                            rowSpan={3}
                            style={{
                                borderColor: "#21aa6c",
                                borderRight: "1px solid white",
                                fontSize: "calc(2vh + 2vw)",
                            }}
                        >
                            {key}
                        </td>
                    ) : key === "Pro" ? (
                        <td
                            rowSpan={3}
                            style={{
                                borderColor: "#e6d607",
                                borderRight: "1px solid white",
                                fontSize: "calc(2vh + 2vw)",
                            }}
                        >
                            {key}
                        </td>
                    ) : (
                        <td rowSpan={3} className={Styles.tableRowSpan}>
                            {key}
                        </td>
                    )}
                    <td>Future</td>
                    <td>{data["Future_Qty_Oi"]}</td>
                    {trendCheckerNetActivity("Future", data["Future_Qty_Oi"])}
                    {trendCheckerView("Future", data["Future_Qty_Oi"])}
                </tr>
                <tr>
                    <td>CE</td>
                    <td>{data["Call_Change"]}</td>
                    {trendCheckerNetActivity("Call", data["Call_Change"])}
                    {trendCheckerView("Call", data["Call_Change"])}
                </tr>
                <tr>
                    <td>PE</td>
                    <td>{data["Put_Change"]}</td>
                    {trendCheckerNetActivity("Put", data["Put_Change"])}
                    {trendCheckerView("Put", data["Put_Change"])}
                </tr>
            </>
        );
    };

    const createFIIFnOBodyRows = (keyFinder) => {
        return (
            <tbody className={Styles.tableBody}>
                {reduxFiiFno != null ? (
                    Object.keys(reduxFiiFno[0])
                        .filter((key) => {
                            if (key === keyFinder) {
                                return true;
                            } else {
                                return false;
                            }
                        })
                        .map((key) =>
                            participantTable(key, reduxFiiFno[0][key])
                        )
                ) : (
                    <></>
                )}
            </tbody>
        );
    };

    const createFnOTable = () => {
        return (
            <div className={Styles.fiiDiiTable}>
                {participantList.map((key) => (
                    <table>
                        <thead>
                            <tr className={Styles.tableHeader}>
                                <th></th>
                                <th>INSTRUMENT</th>
                                <th>NET CHANGES</th>
                                <th>NET ACTIVITY</th>
                                <th>VIEW</th>
                            </tr>
                        </thead>
                        {createFIIFnOBodyRows(key)}
                    </table>
                ))}
            </div>
        );
    };

    return (
        <div className={Styles.fiiFnO}>
            <div className={Styles.fiiFnoOuterContainer}>
                <div className={Styles.fiiFnoInnerContainer}>
                    <div className={Styles.heading}>
                        F&O Participant Wise Open Interest
                    </div>
                    {createFnOTable()}
                </div>
            </div>
            <div className={Styles.chartGroup}>
                <div className={Styles.charts}>
                    <FiiCallPutChangeChart />
                </div>
                <div className={Styles.charts}>
                    <FiiFutureChart />
                </div>
                <div className={Styles.charts}>
                    <FiiCallPutOiChart />
                </div>
                <div className={Styles.charts}>
                    <FiiFutureOiChart />
                </div>
            </div>
        </div>
    );
};

export default FIIFnO;
