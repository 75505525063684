import React, { useEffect, useRef, useState, useCallback } from "react";
import Styles from "../styles/OptionChainStyles.module.css";
import { useDispatch, useSelector } from "react-redux";
import IndicesSummary from "../components/IndicesSummary";
import useWebSocket from "react-use-websocket";
import { useSearchParams } from "react-router-dom";
import {
    indiceList,
    indiceListTotal,
    indicesUrlMap,
    BASESOCKETURL,
    INDEXWISEEXPIRYLISTURL,
    INDEXNAMEWITHEXPIRYURL,
} from "../data/Constants";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import axios from "axios";
import { InputAdornment, MenuItem } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import {
    updateIndices,
    addIndicesDataSingleFetch,
} from "../redux/indices/indicesSlice";
import { updatePerDay } from "../redux/fiiSlice";

const OptionChain = () => {
    const [queryParameters] = useSearchParams();
    const [socketUrl, setSocketUrl] = useState(
        BASESOCKETURL + "NIFTY25APR2024/"
    );
    const dummyData = useSelector((state) => state.indices);
    const tableContainerRef = useRef(null);
    const dispatch = useDispatch();
    const indiceSummary = useSelector((state) => state.fii.perDay);

    const [indexName, setIndexName] = useState("");
    const [expiry, setExpiry] = useState("");
    const [expiryList, setExpiryList] = useState([]);

    const handleClickSendMessage = useCallback(() => {
        sendMessage("Market_Data");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { sendMessage, lastJsonMessage } = useWebSocket(socketUrl);

    function scrollToMiddle() {
        if (tableContainerRef.current) {
            const tableContainer = tableContainerRef.current;
            const middle =
                (tableContainer.scrollHeight - tableContainer.clientHeight) / 2;
            tableContainer.scrollTop = middle;
        }
    }

    useEffect(() => {
        let index = queryParameters.get("index");
        if (
            index !== null &&
            index !== "" &&
            indiceList.indexOf(index) !== -1
        ) {
            setIndexName(index);
        } else {
            setIndexName("NIFTY");
        }
        scrollToMiddle();
        const fetchTodaySpotData = async (spotDataUrl) => {
            try {
                const response = await axios.request({
                    url: spotDataUrl,
                    method: "GET",
                    headers: {
                        Authorization:
                            "Basic bmlmdHlhcGl1c2VyOm5pZnR5YXBpdXNlckAyMTEwIw==",
                    },
                });
                // const indice = response.data.result.symbol_name;
                // temp[indice] = response.data.result;
                dispatch(updatePerDay(response.data.result));
            } catch (error) {
                // console.error(error);
            }
        };
        for (index in indiceListTotal) {
            fetchTodaySpotData(indicesUrlMap[indiceListTotal[index]]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const fetchTokenDataMap = async (currentExpiry) => {
            try {
                let tokenDataMapUrl =
                    INDEXNAMEWITHEXPIRYURL + indexName + currentExpiry;
                const response = await axios.request({
                    url: tokenDataMapUrl,
                    method: "GET",
                });
                dispatch(updateIndices(response.data));
            } catch (error) {
                // console.error(error);
            }
        };

        const fetchExpiryList = async () => {
            try {
                let expiryListUrl = INDEXWISEEXPIRYLISTURL + indexName;
                const response = await axios.request({
                    url: expiryListUrl,
                    method: "GET",
                });
                setExpiryList(response.data.expiry);
                if (response.data.expiry.length !== 0) {
                    setExpiry(response.data.expiry[0]);
                    fetchTokenDataMap(response.data.expiry[0]);
                }
            } catch (error) {
                // console.error(error);
            }
        };
        if (indexName !== null && indexName.length !== 0) {
            fetchExpiryList();
        }
        scrollToMiddle();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [indexName]);

    useEffect(() => {
        setSocketUrl(BASESOCKETURL + indexName + expiry + "/");
        scrollToMiddle();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [expiry]);

    // useEffect(() => {
    //     const fetchExchangeTokenData = async () => {
    //         try {
    //             console.log(dummyData[indexName]);
    //             const response = await axios.request({
    //                 url: ExchangeTokenDataPath,
    //                 method: "POST",
    //                 data: { tokenList: dummyData.nifty.token },
    //             });
    //             console.log(response);
    //         } catch (error) {
    //             console.error(error);
    //         }
    //     };
    //     fetchExchangeTokenData();
    // }, [dummyData]);

    useEffect(() => {
        if (lastJsonMessage !== null) {
            if (
                lastJsonMessage["status"] === "connected New" ||
                lastJsonMessage["status"] === "connected"
            ) {
                handleClickSendMessage();
            }
            if (
                lastJsonMessage["status"] !== "connected New" &&
                lastJsonMessage["status"] !== "connected"
            ) {
                // For continuous data fetch
                // dispatch(addIndicesData(lastJsonMessage));
                dispatch(addIndicesDataSingleFetch(lastJsonMessage));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lastJsonMessage]);

    const createOptionChainBodyRows = () => {
        return (
            <tbody className={Styles.tableBody}>
                {dummyData.nifty.data.map((data) => (
                    <tr>
                        {/* <td>{data.CEAnalysis}</td> */}
                        <td>{data.CEOI}</td>
                        <td>{data.CEVolume}</td>
                        <td>{data.CELTP}</td>
                        <td colSpan="2">
                            {data?.strike !== undefined
                                ? data.strike
                                : "Strike Price"}
                        </td>
                        <td>{data.PELTP}</td>
                        <td>{data.PEVolume}</td>
                        <td>{data.PEOI}</td>
                        {/* <td>{data.PEAnalysis}</td> */}
                    </tr>
                ))}
            </tbody>
        );
    };

    const renderSymbolSearch = () => {
        return (
            <div className={Styles.searchBox}>
                <Autocomplete
                    freeSolo
                    value={indexName}
                    options={indiceList.map((data) => data)}
                    onChange={(e, value) => setIndexName(value)}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            hiddenLabel
                            variant="filled"
                            size="small"
                            placeholder="Search for Index Name"
                            InputProps={{
                                ...params.InputProps,
                                disableUnderline: true,
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    )}
                />
            </div>
        );
    };

    const renderExpiryList = () => {
        return (
            <div className={Styles.expiryBox}>
                <TextField
                    select
                    size="small"
                    value={expiry}
                    fullWidth
                    sx={{
                        "& fieldset": { border: "none" },
                    }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment disableTypography position="end">
                                Expiry
                            </InputAdornment>
                        ),
                    }}
                >
                    <MenuItem key={expiryList[0]} value={expiryList[0]}>
                        {expiryList[0]}
                    </MenuItem>
                </TextField>
            </div>
        );
    };

    return (
        <div className={Styles.optionChain}>
            <div className={Styles.pageHeader}>Option Chain</div>
            <div className={Styles.indexSummary}>
                {indiceListTotal.map((data) => (
                    <IndicesSummary
                        name={data}
                        lastPrice={indiceSummary[data].last_trade_price}
                        change={indiceSummary[data].change_value}
                        changePercent={indiceSummary[data].change_per}
                        key={data}
                    />
                ))}
            </div>
            <div className={Styles.searchFields}>
                <span className={Styles.searchSymbol}>
                    {renderSymbolSearch()}
                </span>
                <span className={Styles.searchExpiry}>
                    {renderExpiryList()}
                </span>
            </div>
            <div className={Styles.optionChainTable}>
                <div className={Styles.tableWrapper} ref={tableContainerRef}>
                    <table>
                        <thead>
                            <tr className={Styles.tableHeader}>
                                <th colSpan="4">Calls</th>
                                <th colSpan="4">Puts</th>
                            </tr>
                            <tr className={Styles.tableSubHeader}>
                                <th>OI - Lakh</th>
                                <th>Volume - Lakh</th>
                                <th>Ltp</th>
                                <th colSpan="2">Strike Price</th>
                                <th>Ltp</th>
                                <th>Volume - Lakh</th>
                                <th>OI - Lakh</th>
                            </tr>
                        </thead>
                        {createOptionChainBodyRows()}
                    </table>
                </div>
            </div>
        </div>
    );
};

export default OptionChain;
